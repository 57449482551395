<template>
  <svg width="55" height="30" viewBox="0 0 55 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.9994 6.01669C41.346 12.9519 44.7422 17.1494 50.279 24.3169C50.5133 24.6202 50.5134 25.0455 50.2775 25.3476C49.9404 25.7793 49.2856 25.7754 48.9487 25.3436C43.8258 18.7759 40.4275 15.3836 28.8786 8.89657C22.0854 5.08077 12.3158 3.83016 2.67051 5.17432C1.72309 5.30635 0.794462 4.79916 0.42529 3.91669C-0.087896 2.68996 0.682579 1.3039 2.00286 1.14556C12.5324 -0.117288 21.2303 1.65272 28.9994 6.01669Z"
      :fill="primaryColors.primary500"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.3049 29.8467C52.7772 29.578 52.9699 28.9956 52.7512 28.4981C51.7612 26.2467 50.757 23.597 51.3971 21.3429C52.0374 19.0882 53.5111 16.9345 53.749 15.773C53.8476 15.2917 54.1241 15.1236 53.9862 14.6116C53.9291 14.3994 53.7745 14.5261 53.4185 14.9911C52.4646 16.2368 51.2178 18.401 50.4972 20.9385C50.0791 22.4111 49.8286 24.0454 49.9371 25.7159C47.7872 24.3004 45.1949 23.6793 42.7006 23.4985C41.0165 23.5734 39.6858 23.8347 37.3914 24.5446C36.8318 24.7177 34.4221 25.6309 34.4953 25.8683C34.6684 26.4278 35.1357 26.268 35.6952 26.0949C37.7941 25.4455 39.7776 24.9516 43.1552 25.1964C46.5481 25.4424 49.0747 26.9623 50.9175 29.5415C51.2334 29.9837 51.8325 30.1155 52.3049 29.8467Z"
      :fill="primaryColors.primary500"
    />
  </svg>
</template>

<script setup lang="ts">
const primaryColors = computed(() => {
  return getPrimaryColors();
});
</script>
